import React from "react";
import { IResourceComponentsProps, CrudFilters, CrudSort, HttpError, useGetIdentity } from "@pankod/refine-core";
import {
  List,
  Table,
  Card,
  Input,
  Icons,
  Form,
  Button,
  FormProps,
  Row,
  Col,
  Space,
  useTable,
  CreateButton,
  DateField,
  Select,
  DatePicker,
  getDefaultSortOrder,
  useSelect,
  Typography,
} from "@pankod/refine-antd";
import { RESOURCE_FIELDS, RESOURCE_NAME } from "./constant";
import { ICaseTemplate, ICaseTemplateType, ILegalCase } from "interfaces";
import { CRUDFILTER_OPERATOR_SOME, PAGINATION_FIELDS } from "libs/constant";
import { LegalCaseOverallStatus } from "components/cases";
import LegalCaseActions from "components/cases/LegalCaseActions";
import { useAdminOptions } from "hooks/use-admin-options";
import { useClientOptions } from "hooks/use-client-options";
import { useSearchParams } from "react-router-dom";

const { Text, Paragraph } = Typography;

export const CaseList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();

  const initialSorter: CrudSort[] = [{ field: "updatedAt", order: "desc" }];
  const initialPageSize = 10;
  const permanentFilter: CrudFilters = [
    {
      field: "branchId",
      operator: "eq",
      value: user?.branchId,
    },
  ];

  const { tableProps, searchFormProps, filters, sorter } = useTable<ILegalCase, HttpError, { q?: string }>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter,
    initialSorter,
    initialPageSize,
    queryOptions: {
      cacheTime: 0,
      staleTime: 0,
      enabled: !!user,
    },
    syncWithLocation: false,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];

      const {
        sequenceId,
        status,
        createdAt,
        caseTemplateTypeId,
        caseTemplateId,
        isWaitingForTitle,
        clientFirstName,
        clientIdentificatioDocumentNumber,
        managedByAdminId,
      } = params;

      filters.push({
        field: "isWaitingForTitle",
        operator: "eq",
        value: isWaitingForTitle === "Yes" ? "true" : isWaitingForTitle === "No" ? "false" : null,
      });

      filters.push({
        field: "sequenceId",
        operator: "contains",
        value: sequenceId,
      });

      filters.push({
        field: "status",
        operator: "in",
        value: status,
      });

      // note(roy): it will be using the local timezone.
      filters.push({
        field: "createdAt",
        operator: "between",
        value:
          createdAt && createdAt.length > 0
            ? [createdAt[0].startOf("day").toISOString(), createdAt[1].endOf("day").toISOString()]
            : undefined,
      });

      filters.push({
        field: "managedByAdminId",
        operator: "eq",
        value: managedByAdminId,
      });

      filters.push({
        field: "caseTemplateTypeId",
        operator: "eq",
        value: caseTemplateTypeId,
      });

      filters.push({
        field: "caseTemplateId",
        operator: "eq",
        value: caseTemplateId,
      });

      let clientValue: any = {
        client: {
          is: {},
        },
      };

      if (clientFirstName) {
        clientValue.client.is.firstName = {
          contains: clientFirstName.trim(),
          mode: "insensitive",
        };
      }

      if (clientIdentificatioDocumentNumber) {
        clientValue.client.is.identificatioDocumentNumber = {
          contains: clientIdentificatioDocumentNumber,
          mode: "insensitive",
        };
      }

      if (clientValue.client.is.firstName) {
        filters.push({
          field: "legalCaseClients",
          operator: CRUDFILTER_OPERATOR_SOME,
          value: clientValue,
        });
      } else {
        filters.push({
          field: "legalCaseClients",
          operator: CRUDFILTER_OPERATOR_SOME,
          value: null,
        });
      } 

      return filters;
    },
  });

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xl={6} lg={24} xs={24}>
        <Card title="Filters">
          <Filter formProps={searchFormProps} filters={filters} branchId={user?.branchId} />
        </Card>
      </Col>

      <Col xl={18} xs={24}>
        <List
          pageHeaderProps={{
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column<ILegalCase>
              className="recent-orders-col"
              key="summary"
              title="Case Identity"
              render={(_, record) => (
                <div className="recent-orders-col__title">
                  <Text className="recent-orders-col__title--up" strong>
                    {record.caseTemplateTypeName}
                  </Text>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      tooltip: record.caseTemplateName,
                      symbol: <span>...</span>,
                    }}
                  >
                    {record.caseTemplateName}
                  </Paragraph>
                  <Text strong className="legalCaseId">
                    #{record.sequenceId}/{record?.managedBy.lastName}
                  </Text>
                </div>
              )}
            />
            {/*<Table.Column
              dataIndex="sequenceId"
              title="File No"
              defaultSortOrder={getDefaultSortOrder("sequenceId", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="caseTemplateTypeName"
              title="Case Type"
              defaultSortOrder={getDefaultSortOrder("caseTemplateTypeName", sorter)}
              sorter
            />
            <Table.Column
              dataIndex="caseTemplateName"
              title="Matter"
              defaultSortOrder={getDefaultSortOrder("caseTemplateName", sorter)}
              sorter
            />*/}
            <Table.Column<ILegalCase>
              dataIndex={"legalCaseClient"}
              title="First Client Name"
              render={(value, record) => {
                const legalCaseClient = record.legalCaseClients.find(Boolean);
                return <Text>{legalCaseClient?.client?.firstName}</Text>;
              }}
            />
            <Table.Column<ILegalCase>
              title="Overall Status"
              render={(value, record) => {
                return (
                  <LegalCaseOverallStatus
                    status={record.status.toString()}
                    description={record.overallStatus.split("_").join(" ")}
                  />
                );
              }}
            />
            <Table.Column
              dataIndex="dueAt"
              title="Due Date"
              defaultSortOrder={getDefaultSortOrder("dueAt", sorter)}
              sorter
              render={(record) => record ? <DateField format="LLL" value={record} /> : 'N/A'}
            />
            <Table.Column
              dataIndex="updatedAt"
              title="Updated At"
              defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
              sorter
              render={(record) => <DateField format="LLL" value={record} />}
            />

            <Table.Column<any>
              fixed="right"
              key="actions"
              title={"Actions"}
              dataIndex="actions"
              render={(_, record) => <LegalCaseActions record={record} />}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const { RangePicker } = DatePicker;
const { Option } = Select;

const Filter: React.FC<{ formProps?: FormProps; filters: CrudFilters; branchId: string }> = (props) => {

  const { selectProps: caseTemplateTypeSelectProps } = useSelect<ICaseTemplateType>({
    resource: "caseTemplateTypes",
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    optionValue: "id",
    optionLabel: "name",
  });

  const caseTemplateTypeId = Form.useWatch("caseTemplateTypeId", props?.formProps?.form);

  const { selectProps: caseTemplateSelectProps } = useSelect<ICaseTemplate>({
    resource: "caseTemplates",
    metaData: {
      items: ["id", "name"],
      metadata: PAGINATION_FIELDS,
    },
    filters: [{ field: "typeId", operator: "eq", value: caseTemplateTypeId }],
    optionValue: "id",
    optionLabel: "name",
  });

  const { selectProps: adminSelectProps, queryResult: adminQueryResult } = useAdminOptions({
    branchId: props.branchId,
  });

  const { selectProps: clientSelectProps, queryResult: clientQueryResult } = useClientOptions({
    branchId: props.branchId,
  });
  
  const [_, setSearchParams] = useSearchParams({});

  const handleResetFilters = () => {
    setSearchParams({});

    props.formProps.form.resetFields();

    props.formProps?.form.submit();
  };

  return (
    <Form
      layout="vertical"
      {...props.formProps}
      initialValues={{
        ...props.formProps?.initialValues,
      }}
    >
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="File No" name="sequenceId">
            <Input placeholder="CON-0001" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Case Type" name="caseTemplateTypeId">
            <Select {...caseTemplateTypeSelectProps} placeholder="Search Case Type" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Matter" name="caseTemplateId">
            <Select {...caseTemplateSelectProps} placeholder="Search Matter" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Managed By" name="managedByAdminId">
            <Select
              {...adminSelectProps}
              options={adminQueryResult?.data?.data?.map((o) => ({
                label: [o.firstName, o.lastName].filter(Boolean).join(" "),
                value: o.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Status" name="status">
            <Select mode="multiple" placeholder="New, In Progress, Closed, Cancelled">
              <Option value="NEW">New</Option>
              <Option value="IN_PROGRESS">In Progress</Option>
              <Option value="CLOSED">Closed</Option>
              <Option value="CANCELLED">Cancelled</Option>
              <Option value="AWAITING_TO_CLOSE">Awaiting To Close</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Client Identification Number" name="clientIdentificatioDocumentNumber">
            <Input placeholder="999999-99-9999" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Client Name" name="clientFirstName">
            <Select
              {...clientSelectProps}
              options={clientQueryResult?.data?.data?.map((o) => ({
                key: o.id,
                label: o.firstName,
                value: o.firstName,
              }))}
              placeholder="Search for client name"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item label="Waiting For Title" name="isWaitingForTitle">
            <Select placeholder="Yes, No">
              <Option value={"Yes"}>Yes</Option>
              <Option value={"No"}>No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xl={24} sm={12} md={8} xs={24}>
          <Form.Item label="Created At" name="createdAt">
            <RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} xl={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="button" onClick={handleResetFilters} type="default">
              Reset Filters
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
